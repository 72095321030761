



















































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import { PostService } from '@/includes/services/PostService'
import { CalendarPosts, CreatedPost } from '@/includes/types/Post.types'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import Quizes from '@/components/Quizes.vue'
import AsyncPopup from '@/components/AsyncPopup.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { getPostText, isEditButtonVisible, multiplyPosts, postObjectFabric, } from '@/includes/PostHelpers'
import PostTypePicker from '@/components/Post/PostTypePicker.vue'
import {
  canAccessPublished,
  canCreatePost,
  canEditAndDeletePost,
  canViewOtherPosts,
  createPostButtonTitle
} from '@/includes/PermissionHelper'
import SuggestPostActionButton from '@/components/SuggestPostActionButton.vue'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import customView from '@/views/custom-fullcalendar-plugin/customView'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { ChannelInfo } from '@/includes/types/Channel.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import isMobile from 'piramis-js-utils/lib/isMobile'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import TgPostPreview from 'piramis-base-components/src/shared/modules/posting/PostPreview/TgPostPreview.vue'
import DatePostsList from 'piramis-base-components/src/shared/modules/posting/PostPreview/components/DatePostsList.vue'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { DatePostsItem, PostPreviewData } from 'piramis-base-components/src/shared/modules/posting/PostPreview/types'
import { PostState, PostType } from 'piramis-base-components/src/shared/modules/posting/types'
import {
  isPostPinned,
  isPostWithDisableNotify,
  isPostWithProtectContent
} from 'piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers'
import PiTabs from 'piramis-base-components/src/components/PiTabs.vue'
import CashIcon from 'piramis-base-components/src/components/Icon/icons/CashIcon.vue'

import { CalendarOptions, EventApi, EventClickArg, EventInput, ViewApi, LocaleInput } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import enLocale from '@fullcalendar/core/locales/en-gb'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru'
import FullCalendar from "@fullcalendar/vue";
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { Drawer } from 'ant-design-vue'
import { EventImpl } from "@fullcalendar/core/internal";
import { Location } from 'vue-router'

@Component({
  data() {
    return {
      CalendarPostsSource,
      PostState,
      EntityTypes
    }
  },
  methods: {
    getPostText,
    createPostButtonTitle,
    canCreatePost,
    canViewOtherPosts,
    canEditAndDeletePost,
    canAccessPublished,
  },
  components: {
    CashIcon,
    TgPostPreview,
    SuggestPostActionButton,
    PostTypePicker,
    AsyncPopup,
    PageTitle,
    Quizes,
    MobileLandscapeTriggerLayout,
    DatePostsList,
    PiTabs,
    FullCalendar
  }
})
export default class PostsPlanner extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  @Ref('drawer') drawer!: Drawer

  isSidebarOpen = false

  currentCustomView = !!isMobile()

  selectedNode: EventImpl | EventInput | null = null

  newModel: Record<string, string> = {
    date: '',
    time: '',
  }

  eventsByDate: Array<DatePostsItem> = []

  isMounted = false

  selectedDate = ''

  selectedTime = ''

  isLoading = false

  isPostTypeModalOpen = false

  query: Location['query'] | null = null

  drawerLoading = false

  historyComponentChange = false

  allEvents: Array<EventInput> = []

  @Ref('movePostModal') movePostModal!: AsyncPopup

  get tabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('planner_calendar_schedule').toString(),
        value: CalendarPostsSource.Schedule,
        icon: {
          name: 'calendar_month'
        }
      },
      {
        label: this.$t('planner_calendar_suggested').toString(),
        value: CalendarPostsSource.Suggested,
        icon: {
          name: 'schedule'
        }
      },
      {
        label: this.$t('planner_calendar_deleted').toString(),
        value: CalendarPostsSource.Deleted,
        icon: {
          name: 'delete'
        }
      },
    ]
  }

  selectPost(post: DatePostsItem) {
    const findPost = this.allEvents.find(value => value.id === post.key)

    if (findPost) {
      this.selectedNode = findPost

      this.historyComponentChange = true
    }
  }

  @Watch('$i18n.locale')
  onLocaleChange(code: string): void {
    this.calendarApi.setOption('locale', code)
  }

  @Watch('$route.params.BOT_ID')
  onIdChange(): void {
    this.calendarApi.refetchEvents()
  }

  get isCopyPostButtonVisible(): boolean {
    if (this.selectedNode) {
      return canCreatePost()
    }

    return false
  }

  get isEditPostButtonVisible(): boolean {
    if (this.selectedNode) {
      const postExtendedProps = this.selectedNode?.extendedProps as CalendarPosts

      return isEditButtonVisible(postExtendedProps as CalendarPosts)
        && ((postExtendedProps.state === PostState.Active && canEditAndDeletePost())
          || (postExtendedProps.state === PostState.Complete && canAccessPublished()))
    }

    return false
  }

  get isRemoveButtonVisible(): boolean {
    const postState = this.selectedNode?.extendedProps?.state

    if (postState === PostState.Complete) {
      return canAccessPublished()
    }

    if (postState === PostState.Active || postState === PostState.Error) {
      return canEditAndDeletePost()
    }

    return false
  }

  get sidebarTitle(): string {
    if (this.selectedNode) {
      return this.$t('planner_page_post_preview').toString()
    } else {
      return this.$t('posts_planner_page_title').toString()
    }
  }

  get calendarApi() {
    return (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi()
  }

  get getFullCalendarLocale(): LocaleInput {
    return localStorage && localStorage.getItem('locale') === 'ru' ? ruLocale : enLocale
  }

  getMainPostInfo(post: CalendarPosts) {
    return {
      botTitle: this.$store.state.boardsState.activeBoard.title,
      state: post?.state,
      message: post.post.message,
      run_time: post.run_time,
      start: post.postTime,
      schedule: post.schedule,
      description: post?.description ?? '',
      split_type: post.split_type,
      targets: this.$store.getters.boardTargetOptions.filter(target => post?.targets.includes(target.value))
    }
  }

  getPostInfo() {
    if (this.selectedNode) {
      const postData = this.selectedNode.extendedProps as CalendarPosts
      const postPreviewData: PostPreviewData['post'] = this.getMainPostInfo(postData)

      const isActiveRepeatPost = Array.isArray(postData.run_time) && postData.run_time.length > 1 && postData.state === PostState.Active
      const nonePostInfoTypes = [ PostState.Deleted, PostState.PublishedDeleted, PostState.Rejected, PostState.New ]

      if (!isActiveRepeatPost && !nonePostInfoTypes.includes(postData.state)) {
        return PostService.getPostInfo('tg', {
          board_key: this.$store.state.boardsState.activeBoard.board,
          post_key: postData.key
        })
          .then(({ data }) => {
            return {
              post: postPreviewData,
              postInfo: ![ PostState.Error, PostState.Complete ].includes(postData.state)
                ? []
                : data.result.map((info: any) => {
                  const target = this.$store.getters.boardTargetOptions.find(target => target.value === info.targetId)

                  return {
                    error: info.error,
                    time: moment(info.time).format('YYYY-MM-DD HH:mm:ss'),
                    target: {
                      avatar: target?.image?.src,
                      title: target?.label
                    }
                  }
                })
                  .sort((a: any, b: any) => moment(a.time).isAfter(moment(b.time)))
            }
          })
          .catch(errorNotification)
      } else {
        return Promise.resolve({ post: postPreviewData })
      }
    }
  }

  gotoSuggestedPost(actionType: 'edit-suggest' | 'show-suggest', post: EventImpl | EventInput): void {
    this.$router.push({
      name: 'post',
      params: {
        actionType
      },
      query: {
        type: post?.extendedProps?.post?.message?.type?.toLowerCase(),
        postId: post.id
      }
    })
  }

  handleSuggestStart(): void {
    this.drawerLoading = true
  }

  handleSuggestDone(): void {
    this.drawerLoading = false
    this.isSidebarOpen = false

    this.calendarApi.refetchEvents()
  }

  getMainEvents(from: string, to: string, successCallback): void {
    this.isLoading = true

    PostService.getPosts('tg', {
      board_key: this.$store.state.boardsState.activeBoard.board,
      interval: { interval_type: 'Full', from, to },
    })
      .then(({ posts }) => {
        if (this.calendarApi.view.type === 'timeGridWeek') {
          successCallback(this.roundPostsTime(this.preparePostsForCalendar(posts)))
        } else {
          successCallback(this.preparePostsForCalendar(posts))
        }
      })
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  setCalendarEventsQuery(source: CalendarPostsSource): void {
    this.$router.replace({ path: this.$route.path, query: { calendar: source } }).catch(() => {
    })
  }

  preparePostsForCalendar(posts: Array<CreatedPost>) {
    let events: Array<EventInput> = []
    const allTheEvents: Array<EventInput> = []

    for (let post of posts) {
      if (post.run_time.length === 1) {
        const calendarPost = postObjectFabric(post.run_time[0], post)

        events.push(calendarPost)
        allTheEvents.push(calendarPost)
      } else {
        const ev = multiplyPosts(post)

        if (!events.length) {
          events = ev.shortedEvents
        } else {
          events.push(...ev.shortedEvents)
        }

        allTheEvents.push(...ev.fullEvents)
      }
    }

    this.allEvents = allTheEvents
    return events
  }

  getDeletedEvents(from: string, to: string, successCallback): void {
    this.isLoading = true

    PostService.getDeletedPosts('tg', {
      board_key: this.$store.state.boardsState.activeBoard.board,
      interval: {
        interval_type: 'Full',
        from,
        to
      },
    })
      .then(({ posts }) => successCallback(this.preparePostsForCalendar(posts)))
      .finally(() => this.isLoading = false)
  }

  getSuggestedEvents(from: string, to: string, successCallback): void {
    this.isLoading = true

    PostService.getSuggestPosts('tg', {
      board_key: this.$store.state.boardsState.activeBoard.board,
      interval: {
        interval_type: 'Full',
        from,
        to
      },
    })
      .then(({ posts }) => successCallback(this.preparePostsForCalendar(posts)))
      .finally(() => this.isLoading = false)
  }

  sidebarCloseHandler(): void {
    this.isSidebarOpen = false
    this.selectedNode = null
    this.eventsByDate = []
  }

  handleEditPost(postId: EventApi['id']): void {
    PostService.getPostInfo('tg', {
      board_key: this.$store.state.boardsState.activeBoard.board,
      post_key: postId
    })
      .then(({ data }) => {
        const { state } = data.post

        if (state === PostState.Active) {
          this.gotoPost(postId, 'edit')
        }
        if (state === PostState.Complete) {
          this.gotoPost(postId, 'edit-published')
        }
      })
      .catch(errorNotification)
  }

  handleRemovePost(postId: EventApi['id']): void {
    PostService.getPostInfo('tg', {
      board_key: this.$store.state.boardsState.activeBoard.board,
      post_key: postId
    })
      .then(({ data }) => {
        const { state } = data.post
        if (state === PostState.Active) {
          this.removeEvent(postId)
        }

        if (state === PostState.Complete || state === PostState.Error) {
          this.removePublishedEvent(postId)
        }
      })
      .catch(errorNotification)
  }

  removePublishedEvent(postKey: string) {
    const h = this.$createElement
    this.$confirm({
      title: this.$t('planner_popup_title_remove_published_warn').toString(),
      content: h('div', {}, [
        h('div', { class: 'ant-alert ant-alert-info ant-alert-no-icon mb-2' }, this.$t('remove_published_post_warning_message').toString()),
        h('span', this.$t('planner_popup_text_remove_published_warn').toString()),
      ]),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        const event = this.calendarApi.getEventById(postKey)

        if (event) {
          PostService.deletePublishedPost('tg', { board_key: this.$store.getters.activeBoard.board, post_key: postKey })
            .then(() => {
              successNotification()
              this.calendarApi.refetchEvents()
            })
            .catch(errorNotification)
        }
        this.isSidebarOpen = false
        this.sidebarCloseHandler()
      },
      centered: true
    })
  }

  removeEvent(postKey: string): void {
    this.$confirm({
      title: this.$t('planner_popup_title_remove_warn').toString(),
      content: this.$t('planner_popup_remove_warn').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        const event = this.calendarApi.getEventById(postKey)
        if (event) {
          PostService.deletePost('tg', { board_key: this.$store.state.boardsState.activeBoard.board, post_key: event.id })
            .then(() => {
              successNotification()
              this.calendarApi.refetchEvents()
            })
            .catch(errorNotification)
        }
        this.isSidebarOpen = false
        this.sidebarCloseHandler()
      },
      centered: true
    })
  }

  gotoPost(postId: EventApi['id'], mode: 'edit' | 'copy' | 'edit-published', query?: Record<string, string>) {
    this.$router.push({
      name: 'post',
      params: {
        actionType: mode
      },
      query: { postId, ...query }
    })
  }

  postTypeSelectHandler(data: { type: PostType, query: Record<string, any> | null }) {
    this.$router.push({
      name: 'post',
      params: {
        actionType: 'new',
      },
      query: {
        ...this.query ? this.query : {},
        type: data.type.toLowerCase(),
        ...data.query
      },
    })
  }

  handleTemplateButtonClick(): void {
    this.$router.push({
      name: "Style",
      params: {
        actionType: BaseItemViewAction.New
      }
    })
  }

  postTime(date: string): string {
    return moment(date).format('HH:mm').toString()
  }

  get calendarOptions(): CalendarOptions {
    return {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
        customView
      ],
      buttonText: {
        custom: this.$t('feed_calendar_view').toString()
      },
      customButtons: {},
      headerToolbar: {
        left: 'today',
        center: 'prev title next',
        right: `dayGridMonth,timeGridWeek,custom`
      },
      initialView: isMobile() ? 'custom' : 'dayGridMonth',
      initialEvents: (arg, successCallback) => {
        successCallback([])
      },
      editable: true,
      selectable: true,
      // в поле хранится дата публикации по факту, т.к. в виде календаря "неделя" идет округление времени, то посты отображаются некорректно, поэтому сортировка указана явно по полю
      eventOrder: 'postTime',
      selectMirror: true,
      initialDate: this.$route.query.date ? this.$route.query.date.toString() : undefined,
      weekends: true,
      eventClick: this.handleEventClick,
      navLinks: true,
      dateClick: (info) => this.handleDateClick(info.dateStr, info.view),
      locale: this.getFullCalendarLocale,
      locales: [ enLocale, ruLocale ],
      contentHeight: 1000,
      events: (fetchInfo, successCallback) => {
        setTimeout(() => {
          if (this.calendarApi.currentData.currentViewType !== 'custom') {
            this.getPostsByCalendarType(fetchInfo, successCallback)
          }
        }, 0)
      },
      viewDidMount: (mountArg) => {
        this.currentCustomView = mountArg.view.type === 'custom'
      },
      dayMaxEvents: true,
      views: {
        custom: {
          type: 'custom',
        }
      },
      eventMaxStack: 3,
      lazyFetching: false,
      moreLinkClick: (info) => this.handleDateClick(moment(info.date).format('YYYY-MM-DD'), info.view),

      eventDrop: (info) => {
        this.setDateTimeToNewModel(info.event.startStr)

        this.movePostModal.open()
          .then(() => {
            this.movePost(info.event.extendedProps.key)
          })
          .catch(() => info.revert())
      },
      eventAllow: (dropLocation, draggedEvent) => {
        if (draggedEvent?.extendedProps.run_time.length > 1) {
          if (moment(dropLocation.startStr).isSameOrBefore(moment().startOf('d')) ||
            moment(draggedEvent?.startStr).format('YYYY-MM-DD HH:mm') !== moment(draggedEvent?.extendedProps.run_time[0]).format('YYYY-MM-DD HH:mm')
          ) {
            return false
          }
          return true
        } else {
          return !moment(dropLocation.startStr).isSameOrBefore(moment().startOf('d'))
        }
      },
      moreLinkContent: (args) => {
        return '+' + args.num
      }
    }
  }

  movePost(post_key: string): void {
    PostService.movePost('tg', {
      board_key: this.$store.state.boardsState.activeBoard.board,
      post_key,
      time: moment(`${ this.newModel.date } ${ this.newModel.time }`).format('YYYY-MM-DD HH:mm:ss')
    })
      .then(() => {
        successNotification()

        this.calendarApi.refetchEvents()
      })
      .catch(errorNotification)
  }

  roundPostsTime(posts: Array<EventInput>) {
    return posts.map(p => {
      if (moment(p.start).minute() >= 30) {
        p.start = moment(p.start).minute(30).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      } else {
        p.start = moment(p.start).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      }

      return p
    })
  }

  setDateTimeToNewModel(date: string): void {
    const formatDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    const splitFormatDate = formatDate.split(' ')

    this.newModel.date = splitFormatDate[0]
    this.newModel.time = splitFormatDate[1]
  }

  handleDateClick(dateStr: string, calendar: ViewApi) {
    let date = ''

    if (calendar.type === 'timeGridWeek') {
      const splitDateTime = dateStr.split('T')

      date = splitDateTime[0]
      this.selectedTime = splitDateTime[1]
    } else {
      date = dateStr
    }

    this.selectedDate = date
    this.eventsByDate = this.allEvents
      .filter(e => e.extendedProps && e.extendedProps.postTime.split(' ')[0] === date)
      .map(value => {
        const calendarPost = (value.extendedProps as CalendarPosts)

        return {
          key: value.id!,
          type: calendarPost.post.message.type,
          start: moment(calendarPost.postTime).toDate(),
          text: getPostText(calendarPost.post.message),
          description: calendarPost.description,
          classNames: value.classNames as Array<string>,
          targets: this.$store.state.boardsState.activeBoard.channels
            .reduce((acc: Array<SelectOptionData>, target: ChannelInfo) => {
              if (calendarPost.targets.includes(target.id)) {
                acc.push({ image: { src: target.avatar }, value: target.id, label: target.title })
              }

              return acc
            }, []),
          pin: isPostPinned(calendarPost.post.message),
          protectContent: isPostWithProtectContent(calendarPost.post.message),
          disableNotify: isPostWithDisableNotify(calendarPost.post.message),
          isRepeat: Array.isArray(calendarPost.run_time) && calendarPost.run_time.length > 1
        }
      })

    this.isSidebarOpen = true
  }

  gotoCreatePostPage(postMeta?: { date: string, query?: Location['query'] }): void {
    this.isPostTypeModalOpen = true
    this.query = postMeta?.query
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.isSidebarOpen = true
    this.selectedNode = cloneDeep(clickInfo.event)
  }

  getPostsByCalendarType(fetchInfo, successCallback): void {
    const calendarQuery = this.$route.query.calendar?.toString() as CalendarPostsSource
    const from = moment(fetchInfo.start).format('YYYY-MM-DD')
    const to = moment(fetchInfo.end).format('YYYY-MM-DD')

    if (calendarQuery === CalendarPostsSource.Schedule) {
      this.getMainEvents(from, to, successCallback)
    } else if (calendarQuery === CalendarPostsSource.Suggested) {
      this.getSuggestedEvents(from, to, successCallback)
    } else if (calendarQuery === CalendarPostsSource.Deleted) {
      this.getDeletedEvents(from, to, successCallback)
    } else {
      this.$router.replace({ path: this.$route.path, query: { calendar: CalendarPostsSource.Schedule } }).catch(() => {
      })
    }
  }

  scrollDrawer(y: number): void {
    const bodyArr = this.drawer.$el.getElementsByClassName('ant-drawer-body')

    if (bodyArr.length) {
      const body = bodyArr[0]

      body.scroll({ behavior: 'smooth', top: y })
    }
  }

  mounted() {
    if (this.$store.state.shopState.products === null) {
      this.$store.dispatch('getProducts')
    }

    this.isMounted = true
  }
}
